import * as Types from '../Types';

export const increaseCounter = () => {

    return {
        type: Types.INCREMENT,
    };

};

export const decreaseCounter = () => {

    return {
       type: Types.DECREMENT

    };
};
export const getLoginUser = (data) => {

    return {
       type: Types.GET_LOGIN_USER,
       data:data
    };
};
export const onLogOutUser = () => {

    return {
       type: Types.LOGOUT,    
    };
};

export const onShowProfile=(data)=>{
    
    return {
        type: Types.SHOW_PROFILE_CANDIDATE,
        data:data
    }
}

export const onGetSystemLevetlData=(data)=>{
    
    return {
        type: Types.GET_DUMMY_CITIES,
        data:data
    }
}
