import React, { Component } from 'react'
import { connect } from "react-redux"
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { getSystemLevelData } from "./helper/SystemLevelData"
import * as action from "./redux/actions/index"
import { displayToaster } from 'src/helper/Toaster';

import './scss/style.scss'
import { getUserGetMeToken } from './helper/UserGetMeToken'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const DigitalProfileV2= React.lazy(()=>import('./views/pages/profile/DigitalProfileV2'))
const CandidateDigitalProfile= React.lazy(()=>import('./views/pages/profile/PublicDigitalProfile'))
const DigitalProfileCandiate = React.lazy(()=>import('./views/pages/profile/DigitalProfileRecruiter'))

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      token: localStorage.getItem("token")
    }
  }
  componentDidMount() {
    this.getSystemData()
    if (this.state.token != null) {
      this.getLoginUser()    
    }
  }

  getSystemData = ()=>{
    getSystemLevelData()
    .then((res) => {
      var dummyCities = res.data.Data.dummyCities
      this.props.onGetSystemDefinedData(dummyCities)
    })
  }

  getLoginUser = () => {

    if (this.state.token != null){
      getUserGetMeToken(this.state.token)
      .then(res => {
        this.props.onGetLoginUser(res.data.Data)
        sessionStorage.setItem('auth',JSON.stringify(res.data.Data))
        localStorage.setItem('role_id',JSON.stringify(res.data.Data.content.userData.role_id))
      })
      .catch(error=>{
        displayToaster("error",error.message)
      })
    }
    
  }
  render() {
    const { user } = this.props;
    return (
      <>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route exact path="/profile/digital-proile" name="Profile" render={(props) => <DigitalProfileV2 {...props} />} />
              <Route exact path="/profile/digital-proile1" name="Profile" render={(props) => <DigitalProfileV2 {...props} />} />
              <Route exact path="/profile/candidate-digital-profile" name="Profile" render={(props) => <CandidateDigitalProfile {...props} />} />
              <Route exact path="/profile/candidate-profile" name="Profile" render={(props) => <DigitalProfileCandiate {...props} />} />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} auth={user} />} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isAuthUser:state.auth.isAuthUser,
    userRole:state.auth.userRole
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetLoginUser: (data) => dispatch(action.getLoginUser(data)),
    onGetSystemDefinedData:(data)=> dispatch(action.onGetSystemLevetlData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)


