import axios from "../axios"


export async function getSystemLevelData() {

    if(localStorage.getItem('SLD') == null){
        const response = await axios.get('/get-system-level-data/',{})
        localStorage.setItem('SLD',JSON.stringify(response))
        return response
    }

    return new Promise(function(myResolve) {
        // "Producing Code" (May take some time)

          myResolve(JSON.parse( localStorage.getItem('SLD'))); // when successful
         // myReject();  // when error
        })

  }

