import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import store from './redux/store'
import axios from './axios'
import { displayToaster } from './helper/Toaster'

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//     : compose;

// const rootReducer = combineReducers({
//   auth: Auth
// });
// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_API_GOOGLE_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>

  </Provider>,
  document.getElementById('root'),
)

// this will intercept response and if its 401 error handle
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
      displayToaster('error', "Your Not Authorized. Login first! Please.")
      setTimeout(function () {
        window.location.href = '/login'
    }, 3000);

  } else {
      return Promise.reject(error);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
