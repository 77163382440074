
import * as types from '../Types';

const INITIAL_STATE = {
	count: 0,
	user:null,
	isAuthUser:false,
	userRole:0,
	dummyCities: [],
	showProfile: false
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.INCREMENT:
			return {
				...state,
				count: state.count + 1,
			};
		case types.DECREMENT:
			return {
				...state,
				count: state.count - 1,
			};
		case types.GET_LOGIN_USER:
			return {
				...state,
				user: action.data,
				isAuthUser:true,
				userRole:action.data.content.userData.role_id
			};
		case types.LOGOUT:
			return {
				...state,
				user: null,
				isAuthUser: false
			};
		case types.GET_DUMMY_CITIES:
			return {
				...state,
				dummyCities : action.data
			}
		case types.SHOW_PROFILE_CANDIDATE:
			return {
				...state,
				showProfile : action.data
			}
		default:
			return state;
	}
};

export default reducer;