import axios from "../axios"


export async function getUserGetMeToken(token) {

    const response = axios.get('user-get-me/?token=' + token, {
        headers: {
          Authorization: `token ${token}`,
        }
      })

    return response

}

